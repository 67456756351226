<template>
  <div>
    <h5 class="pb-2">Software</h5>
    <div class="_main">
      <CCol sm="12" md="12" class="_no-padding">
        <CCard class="_card-container">
          <CCardHeader class="_card-header-parent mt-2" v-if="pages.length !== 0">
            <div>
              <strong class="mr-2">Software:</strong>                        
              <CButton v-for="(item, index) in pages" :key="index" size="sm" color="blue-dark" class="_btn-parent" @click="reset(index)">
                <i class="far fa-dot-circle mr-2"></i> {{item.descriptionKey}}
              </CButton>         
            </div>
          </CCardHeader>

          <CCardBody class="_card-body">
            <Software v-if="pages.length === 0" @set-hierarchy-level="hierarchyLevel = $event"></Software>
            <div class="_main-parent">
              <CTabs :active-tab.sync="activeTab" v-model="activeTab">
                <!-- Nivel 01 -->
                <CTab v-show="activeLevel===1">
                  <template slot="title">Módulos </template>
                  <SoftwareModule :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></SoftwareModule>
                </CTab>   
                <!-- Nivel 02 -->
                <CTab v-show="activeLevel===2">
                  <template slot="title">Eventos </template>
                  <SoftwareEvent :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></SoftwareEvent>
                </CTab>   
                    
              </CTabs>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  </div>
</template>

<script>
import Software from './Software'
import SoftwareModule from './module/SoftwareModule'
import SoftwareEvent from './event/SoftwareEvent'

export default {
  name: 'SoftwareContainer',
  components: {Software,SoftwareModule,SoftwareEvent },
  computed: {
    getFirstPage() {
      return this.pages[0];
    },    
    getLastPage() {
      return this.pages[this.pages.length-1];
    },
    activeLevel() {
      return this.pages.length
    }
  },
  data() {
    return {
      activeTab: 0,
      hierarchyLevel: {},
      pages: [],
      tabs:[
        {"parentName":"Software","tabIndex":0},
        {"parentName":"SoftwareModule","tabIndex":1},
      ]
    }
  },
  methods: {
    setTabIndex(payload){
      let tab = this.tabs.filter(i => (i.parentName === payload.parentName));
      this.activeTab = tab[0].tabIndex
    },
    reset(payload) {
      if(payload === 0) {
        this.pages = []
      } else {
        this.pages.splice(payload);
        this.setTabIndex(this.getLastPage)
      } 
    }
  },
  watch: {
    hierarchyLevel: {
      immediate: true,
      handler(hierarchyLevel) {
        if (Object.entries(this.hierarchyLevel).length !== 0) {
          this.pages.push(hierarchyLevel)
          this.setTabIndex(hierarchyLevel)
        }
      },
    },
  }
}
</script>
