<template>
  <div>
    <FormFilter @set-action-filter="action = $event">
      <template slot="default">        
        <CInput id="component_parameter_id" label="ID" v-model="filter.component_parameter_id" />
        <CSelect id="component_id" label="Componente" :options="componentOptions" v-model="filter.component_id" @update:value="filter.component_id = $event"/>
      </template>
    </FormFilter>
  </div>
</template>

<script>
import FormFilter from "@/components/FormFilter";

export default {
  name: "SoftwareModuleFilter",
  components: { FormFilter },
  props: {
    componentOptions: Array
  },
  data() {
    return {
      action: {},
      filter: {}
    }
  },
  methods: {
    apply() { 
      this.filter.apply = true
      this.triggerEvent()     
    },
    reset() {
      this.filter = {}
      this.filter.reset = true
      this.triggerEvent()   
    },
    triggerEvent() { 
      this.$emit('filter-trail-component', this.filter);
      this.action = {}   
      if(this.filter.reset) this.filter = {}
    }
  },
  watch: {
    action() {
      if(this.action === 'applyFilter') {
        this.apply()     
      } else if(this.action === 'resetFilter') {
        this.reset()        
      }
    }
  }
}
</script>
